import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const DienstenPage = () => (
  <Layout>
    <Seo title="Diensten" />
    <section className="page-header">
      <h1>Diensten</h1>
    </section>    
    <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8 md:pb-28 xl:pb-40">
          Oogtest
        </h2>
        <p>U kan bij ons een afspraak maken voor een oogtest onder uiterst professionele begeleiding. Er wordt gebruik gemaakt van de oogscanner zodat alles precies tot op 1/100 nauwkeurig wordt opgemeten. Zo krijgt u het beste resultaat</p>
        <p>Bij Optiek Tom De Bruyne worden de oogtesten enkel en alleen uitgevoerd door gediplomeerde opticiens en optometristen. U verdient toch ook het beste zicht?</p>
        <Link to="/contact" className="button bg-tdb-light hover:bg-tdb-dark text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-end">
        <StaticImage
            src="../images/oogmeting_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
        <StaticImage
            src="../images/oogmeting_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        />
      </div>
      <div className="section-bg-left"></div>
    </section>

    <section className="section">
      <div className="section-content-left">
        <h2 className="pb-8 md:pb-28 xl:pb-40">
          Myopie management
        </h2>
        <p>Wat doen we het toch goed hè, voor onze kinderen? We geven ze liefde, gezond eten en aandacht. Maar hoe zorgen wij voor hun ogen?</p>
        <p>Bijziendheid komt steeds vaker voor, ook bij jongeren. Wist je dat op dit moment al zeker 50% van de Europese jongeren bijziend is? De ernst van het probleem wordt bovendien ook groter. De sterkste toename vindt meestal plaats tussen de leeftijd van 6 en 17 jaar oud. In deze groep is het gevaar op bijziendheid het grootst.</p>
        <Link to="/diensten/myopiemanagement" className="button bg-tdb-light hover:bg-tdb-dark text-white mr-2" title="Meer informatie over myopie management ...">Meer info</Link>
        <Link to="/contact" className="button bg-tdb-light hover:bg-tdb-dark text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-7 col-end-13 row-start-1 row-end-1 z-10 h-full flex justify-end items-end">
        <StaticImage
            src="../images/myopiemgmt_4.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        />
        <StaticImage
            src="../images/myopiemgmt_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-right"></div>
    </section>


</Layout>
)

export default DienstenPage
